import { ReproductorComponent } from './reproductor/reproductor.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { NavComponent } from './nav/nav.component';


@NgModule({
  declarations: [
    NavComponent,
    ReproductorComponent,
  ],
  exports: [
    NavComponent,
    ReproductorComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class ComponentsModule { }
