import { Component , Directive, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-reproductor',
  templateUrl: './reproductor.component.html',
  styleUrls: ['./reproductor.component.scss'],
})
export class ReproductorComponent {

  @ViewChild('audio') audioPlayerRef: ElementRef;
  @ViewChild('play') playElement: ElementRef;

  url: any = 'https://audio.websysfan.com/8010/stream';

  onAudioPlay(){
    if (this.audioPlayerRef.nativeElement.paused || this.audioPlayerRef.nativeElement.ended) {
      this.playElement.nativeElement.querySelector('.pause-btn').classList.toggle('hide');
      this.playElement.nativeElement.querySelector('.play-btn').classList.toggle('hide');
      this.audioPlayerRef.nativeElement.play();
    }else {
      this.audioPlayerRef.nativeElement.pause();
      this.playElement.nativeElement.querySelector('.pause-btn').classList.toggle('hide');
      this.playElement.nativeElement.querySelector('.play-btn').classList.toggle('hide');
    }
  }

}
