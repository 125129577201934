import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';



import { Menu } from 'src/app/interfaces/interface';
import { Observable } from 'rxjs';
import { MenuService } from './services/menu.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  
  routes: Observable<Menu[]>;

  constructor(private menuCtrl: MenuController,
              private menuService: MenuService ) { }

  ngOnInit() {
    this.routes =  this.menuService.getMenu();
  }

  mostrarMenu() {
    this.menuCtrl.open('first');
  }

}
